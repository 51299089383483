<template>
  <div class="order-detail">
    <baseContainer page="员工详情" back @cancel="isEdit = false">
      <div class="order-info">
        <el-button class="iconfont icon-bianji" type="primary" @click="isEdit = true" v-show="!isEdit">编辑</el-button>
        <div class="edit-btn">
          <el-button type="primary" class="iconfont icon-dcicon_save1" @click="saveEdit" v-show="isEdit">保存</el-button>
          <el-button class="iconfont icon-dcx" @click="isEdit = false" v-show="isEdit">取消</el-button>
        </div>
        <div class="split-title"><span>基本信息</span></div>
        <div class="order-user-info">
          <div class="info-left-box">
            <div class="info-left">
              <el-avatar
                :size="50"
                :src="detailInfo.userAvatar"
                @error="
                  () => {
                    return true;
                  }
                "
              >
                <img src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" />
              </el-avatar>
              <div class="user-info" v-show="!isEdit">
                <strong>{{ detailInfo.realName }}</strong
                ><span class="inon">{{ detailInfo.workStatus == '1' ? '在职' : '离职' }}</span>
                <p>{{ detailInfo.userMobile }}</p>
              </div>
              <div class="user-edit-info" v-show="isEdit">
                <div>
                  <div><el-input v-model="realName" /></div>
                  <!-- <div><el-input v-model="detailInfo.userMobile" /></div> -->
                </div>
                <div class="is-on">
                  <el-radio-group v-model="detailInfo.workStatus">
                    <el-radio :label="1">在职</el-radio>
                    <el-radio :label="0">离职</el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
            <div class="info-detail">
              <div>
                <span v-if="!isEdit"><strong>工号：</strong>{{ detailInfo.userWorkId }}</span>
                <span v-else><strong>工号：</strong><el-input style="width: 338px; display: inline-block" v-model="detailInfo.userWorkId" maxlength="20" /></span>
                <span><strong>提交量：</strong>{{ detailInfo.orderSubmitNumber }}</span>
                <span> <strong>积分：</strong>{{ detailInfo.totalPoints }} <i class="iconfont icon-bianji" @click="change" v-show="false"></i></span>
              </div>
              <div>
                <span><strong>创建日期：</strong>{{ formatDate(detailInfo.createTime) }}</span>
                <span><strong>处理量：</strong>{{ detailInfo.orderHandledNumber }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </baseContainer>
    <baseDialog ref="dialog" />
    <div class="order-tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
        <el-tab-pane label="处理记录" name="first">
          <list-container :grid="false" :search="false" :pagination="paginationRecord" :isAdd="false" @fetch="loadData">
            <template v-slot:table>
              <base-table :data="recordList" :columns="recordColumns" :isCheck="false" :operations="recordOperations" :multi="false" />
            </template>
          </list-container>
        </el-tab-pane>
        <el-tab-pane label="积分明细" name="second">
          <list-container :grid="false" :search="false" :pagination="paginationScore" :isAdd="false" @fetch="loadScoresData">
            <template v-slot:table>
              <base-table :data="scoreList" :columns="scoreColumns" :operations="scoreOperations" :isCheck="false" :multi="false" :hasHandle="false" />
            </template>
          </list-container>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import listContainer from '@/components/list-container/list-container';
import baseTable from '@/components/table/base-table/base-table';
import baseContainer from '@/components/container/base-container';
import baseDialog from '@/components/user-dialog/user-dialog';
import { TABLE } from '/app.config';
import { table, record, score } from './mixin';
import '@/common/style/detail.scss';
export default {
  name: 'personDetail',
  props: ['id'],
  mixins: [table, record, score],
  data() {
    return {
      isEdit: false,
      paginationRecord: {
        total: 0,
        pageSize: TABLE.page_sizes[0],
        currentPage: 1
      },
      paginationScore: {
        total: 0,
        pageSize: TABLE.page_sizes[0],
        currentPage: 1
      },
      recordList: [],
      scoreList: [],
      userId: '',
      orderType: '',
      problemType: '',
      status: '',
      formLabelWidth: '80px',
      scoreForm: {
        score: '',
        desc: '',
        isBest: true
      },
      scoreRules: {
        score: [{ required: true, message: '请输入积分', trigger: 'blur' }]
      },
      dialogSetFormVisible: false,
      dialogSetShowVisible: false,
      isShow: true,
      activeName: 'first',
      detailInfo: {},
      realName: ''
    };
  },
  activated() {
    this.isEdit = false;
    this.getDetail();
  },
  computed: {
    defaultImg() {
      return `this.src="${require('@/assets/img/default.png')}"`;
    }
  },

  methods: {
    async loadData({ currentPage, currentPageSize } = {}) {
      this.paginationRecord.currentPage = currentPage || this.paginationRecord.currentPage;
      this.paginationRecord.pageSize = currentPageSize || this.paginationRecord.pageSize;

      const params = {
        userId: this.userId,
        page: this.paginationRecord.currentPage,
        limit: this.paginationRecord.pageSize
      };

      const { data, count } = await this.$apis.sysUser.listPagesFeOrder(params);
      this.recordList = data;
      this.paginationRecord.total = count;
    },
    async loadScoresData({ currentPage, currentPageSize } = {}) {
      this.paginationScore.currentPage = currentPage || this.paginationScore.currentPage;
      this.paginationScore.pageSize = currentPageSize || this.paginationScore.pageSize;

      const params = {
        userId: this.userId,
        page: this.paginationScore.currentPage,
        limit: this.paginationScore.pageSize
      };

      const { data, count } = await this.$apis.sysUser.listPages(params);
      this.scoreList = data;
      this.paginationScore.total = count;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    async getDetail() {
      const { data } = await this.$apis.sysUser.getDetail(this.id);
      this.detailInfo = data;
      this.userId = data.userId;
      this.realName = data.realName;
      this.loadData();
      this.loadScoresData();
    },
    // 详情
    view({ status, id }) {
      if (status !== 'forHandle' && status !== 'forAcceptance') {
        this.$router.push({ name: 'completed_detail', params: { id } });
      } else {
        this.$router.push({ name: 'working_detail', params: { id } });
      }
    },
    //修改积分弹框
    change() {
      this.$refs.dialog.change();
    },
    async saveEdit() {
      const reg = /^[A-Za-z0-9]+$/;
      let params = {
        id: this.id,
        realName: this.realName,
        userWorkId: this.detailInfo.userWorkId,
        workStatus: this.detailInfo.workStatus
      };
      console.log(reg.test(params.userWorkId));
      if (!params.realName) {
        this.$message.warning('请输入用户名');
        return false;
      }
      if (!params.userWorkId || !reg.test(params.userWorkId)) {
        this.$message.warning('工号为字母和数字组成，长度为20个字符内');
        return false;
      }
      const { resp_code, resp_msg } = await this.$apis.sysUser.update(params);
      if (resp_code === 1) {
        this.$message({
          type: 'error',
          message: resp_msg
          // onClose() {
          //   location.reload();
          // }
        });
      }
      if (resp_code === 0) {
        this.$message({
          type: 'success',
          message: '修改成功',
          onClose: () => {
            this.isEdit = false;
            this.getDetail();
          }
        });
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      this.$confirm('是否放弃编辑?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
          next();
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
          next(false);
        });
    } else {
      next();
    }
  },
  watch: {
    searchText() {
      // this.loadData();
    }
  },
  components: {
    baseContainer,
    listContainer,
    baseTable,
    baseDialog
  }
};
</script>
<style lang="scss" scoped>
.inon {
  color: #19a572;
  width: 42px;
  height: 24px;
  background: rgba(25, 165, 114, 0.1);
  border-radius: 4px;
  display: inline-block;
  text-align: center;
}
.info-center {
  display: flex;
  justify-content: space-around;
}

.order-detail {
  // padding: 20px;
  .order-info {
    // margin-top: 20px;
  }

  .order-user-info {
    margin-top: 39px;
    padding-left: 20px;
    .info-detail {
      width: 100%;
      div {
        display: flex;
        justify-content: start;
        margin-bottom: 20px;
        span {
          display: block;
          width: 33%;
        }
      }
      strong {
        color: #5a5a5a;
        font-weight: normal;
      }
      .iconfont {
        cursor: pointer;
        &:hover {
          color: #f39800;
        }
      }
    }
    .user-edit-info {
      margin-left: 10px;
      display: flex;
    }
    .is-on {
      margin-left: 204px;
    }
  }
}
</style>
